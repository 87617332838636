<template>
  <div class="case" :class="{'m_top':isMobile == '移动端'}">
    <banner :img="imgUrl" title="新闻资讯" />
    <div :class="[isMobile == '移动端' ? 'case-sectionyd':'case-sectionpc']" v-loading="loading">
      <Headline title="新闻" subtitle="NEWS"></Headline>
      <div class="case-section-content">
        <!-- 列表 -->
        <div class="case-section-content-list" v-for="(cas,index) in caseList" :key="index">
          <!-- 介绍 -->
          <div class="content-list-abstract" :class="{'abstract-active' : index%2!=1}">
            <p class="abstract-time">{{ onUpdateTime(cas.updateTime) }}</p>
            <p class="abstract-title">{{cas.newsTitle}}</p>
            <p class="abstract-content">{{cas.newsContent}}</p>
            <div class="more">
              <router-link
                class="text-decoration"
                :to="{ name: 'casedetails', params: { id: cas.newsId }}"
              >
                <!-- <span>more</span>
                 -->
                <button>more<i class="el-icon-plus"></i></button>
              </router-link>
            </div>
          </div>
          <!-- 图片 -->
          <img v-lazy="imgserver+cas.newsPic" />
        </div>
        <!-- 分页 -->
        <div class="case-section-content-pagination" :class="{'pagination_sty':isMobile == '移动端'}">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNews } from "@/api/index";
import Banner from "../components/Banner";
export default {
  components: {
    Headline: () => import("../components/headline"),
    Banner
  },
  data() {
    return {
      loading: true,
      caseList: [],
      imgUrl: require("@/assets/images/bage1.png"),

      pageNum: 1,
      total: 0,
    };
  },
  computed: {
    isMobile(){
      return this.$store.state.page.isMobile
    },
  },
  mounted() {
    window.scrollTo(0,0);
    this.getList();
  },
  methods: {
    getList(){
      getNews({ pageNum: this.pageNum, pageSize: 8 }).then((res) => {
        if (res.code == 200) {
          this.caseList = res.rows;
          this.total = res.total;
          this.loading = false;
        }
      }).catch((err) => {
        this.$message.error('列表数据获取失败！');
      });;
    },
    handleCurrentChange(val) {
      window.scrollTo(0,0);
      this.pageNum = val;
      this.getList();
    },

    onUpdateTime(e){
      console.log(this.imgserver);
      let time = e.split(' ')[0];
      return time
    },
  }
};
</script>

 <style lang="scss" scoped>
 .m_top{
   margin-top: 230px;
 }
.case {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  //pc端
  &-sectionpc {
    width: 100%;
    margin-top: 60px;
    .case-section-content {
      width: 1240px;
      min-height: 800px;
      margin: 60px auto !important;
      background-color: #fff;
      .case-section-content-list {
        width: 100%;
        height: 400px;
        margin: 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        // border-radius: 5px;
        // box-shadow: 0 0 10px rgba(0,0,0,.5);
        border-bottom: 2px solid #3370f3;
        img {
          width: 400px;
          max-height: 350px;
        }
        .content-list-abstract {
          width: 600px;
          height: 350px;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          .abstract-time{
            height: 70px;
            line-height: 70px;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #666666;
          }
          .abstract-title {
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 800;
            color: #666666;
            line-height: 30px;
            margin-bottom: 40px;
          }
          .abstract-content {
            width: 600px;
            height: 95px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            white-space: normal !important;
            word-wrap: break-word;
          }
          .more {
            width: 90px;
            margin-top: 20px;
            .text-decoration {
              text-decoration: none;
              button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 20px;
                border-radius: 16px;
                border: 1px solid transparent;
                color: #FFFFFF;
                background-color: #3370f3;
                font-size: 16px;
                line-height: 14px;
                letter-spacing: 1px;
                transition: all 0.15s linear;
                cursor: pointer;
              }

              button:hover {
                background-color: #f6f6f8;
                border-color: #3370f3;
                color: #3370f3;
                transform: translateY(-5px) scale(1.05);
              }

              button:active {
                background-color: transparent;
                border-color: #3370f3;
                color: #3370f3;
                transform: translateY(5px) scale(0.95);
              }

              button:disabled {
                background-color: rgba(255, 255, 255, 0.16);
                color: #8E8E93;
                border-color: #8E8E93;
              }
            }
          }
        }
        .abstract-active {
          // order: -1;
        }
      }

      // 分页
      &-pagination {
        text-align: right;
      }
    }
  }
  // 移动端
  &-sectionyd {
    width: 100%;
    margin-top: 60px;
    .case-section-content {
      width: 1800px;
      min-height: 800px;
      margin: 60px auto !important;
      background-color: #fff;
      .case-section-content-list {
        width: 100%;
        height: 750px;
        margin: 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        // border-radius: 5px;
        // box-shadow: 0 0 10px rgba(0,0,0,.5);
        border-bottom: 2px solid #3370f3;
        img {
          width: 600px;
          max-height: 350px;
        }
        .content-list-abstract {
          width: 1200px;
          height: 700px;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          .abstract-time{
            height: 100px;
            line-height: 100px;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #666666;
          }
          .abstract-title {
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 800;
            color: #666666;
            line-height: 80px;
            margin-bottom: 40px;
          }
          .abstract-content {
            max-height: 600px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            white-space: normal !important;
            word-wrap: break-word;
          }
          .more {
            width: 90px;
            margin-top: 20px;
            .text-decoration {
              text-decoration: none;
              button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 20px;
                border-radius: 16px;
                border: 1px solid transparent;
                color: #FFFFFF;
                background-color: #3370f3;
                font-size: 16px;
                line-height: 14px;
                letter-spacing: 1px;
                transition: all 0.15s linear;
                cursor: pointer;
              }

              button:hover {
                background-color: #f6f6f8;
                border-color: #3370f3;
                color: #3370f3;
                transform: translateY(-5px) scale(1.05);
              }

              button:active {
                background-color: transparent;
                border-color: #3370f3;
                color: #3370f3;
                transform: translateY(5px) scale(0.95);
              }

              button:disabled {
                background-color: rgba(255, 255, 255, 0.16);
                color: #8E8E93;
                border-color: #8E8E93;
              }
            }
          }
        }
        .abstract-active {
          // order: -1;
        }
      }

      // 分页
      &-pagination {
        text-align: right;
        
        .el-pager{
          li{
            font-size: 60px;
          }
        }
      }
    }
  }
}
.pagination_sty ::v-deep .btn-prev, .pagination_sty ::v-deep .btn-next{
  width: 100px;
  height: 100px;
  font-size: 90px;
}
.pagination_sty ::v-deep .el-pager li{
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 90px;
  margin: 0 30px;
}
</style>